
.info {
    padding: 16px;
    background-color: var(--white);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
}

.error-msg {
    text-align: left;
    color: red;
    font-weight: 500;
    font-family: "Lato";
}

@media only screen and (max-width: 490px) {
    .info {
        padding: 12px;
        background-color: var(--white);
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 16px;
    }  
}
.cart-section {
  background-color: #FFFFFF;
  border: 5px solid #FFFFFF;
  border-radius: 10px;
  padding: 1px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  min-width: 110px;
  height: 100%;

  .cart-icon {
    margin-right: 5px;
    height: 100%;
  }

  .cart-value {
    position: relative;
  }
}

@media only screen and (max-width: 400px) {
  .cart-icon {
    width: 14px;
  }

  .cart-section {
    font-size: 13px;
  }
}
.addons-ticket-header {
    display: flex;
    flex-wrap: wrap;

    .ticket-header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .left-ticket-header {
            flex: 0 0 60%;
            display: flex;
            align-items: center;

            .no-line-height {
                line-height: unset;
            }
        }

        .right-ticket-header {
            flex: 0 0 40%;
            margin-left: auto;
        }
    }
}

.list-ticket {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media only screen and (max-width: 490px) {
    .list-ticket {
        margin: 16px 0;
    }

    .left-title {
        font-size: 15px;
    }
}

@media only screen and (max-width: 400px) {
    .list-ticket {
        margin: 16px 0;
    }
}

.event-tabs {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .active {
        color: var(--white);
        border-bottom: 2px solid var(--primary);
    }

    .inactive {
        color: #8f8f8f;
        border-bottom: 2px solid #191919;
    }

    .event-tab {
        width: 50%;
        list-style-type: none;
        text-align: center;
        padding-bottom: 10px;
        padding-top: 10px;
        cursor: pointer;
    }
}

.list-event {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 40vh;
    min-height: 10vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .no-event {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 22px;
        align-items: center;

        .no-event-title {
            color: #999999;
        }
    }
}

@media only screen and (max-width: 490px) {
    .list-ticket {
        margin: 16px 0;
    }
}
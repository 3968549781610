.wrapperBox {
  padding: 16px;
  background-color: var(--white);
  border-radius: 24px;
  display: flex;
  display: "-webkit-box";
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 24px;
  font-size: 18px;
}
.linkTerm {
  color: var(--background);
}
.onlyText {
  font-size: 18px;
  font-weight: 700;
  color: var(--primary);
  line-height: 24px;
}

.questions-ticket-header {
  display: flex;
  flex-wrap: wrap;
}

.ticket-header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.left-ticket-header {
  flex: 0 0 60%;
  display: flex;
  align-items: center;
}

.no-line-height {
  line-height: unset;
}

.right-ticket-header {
  flex: 0 0 40%;
  margin-left: auto;
}

@media only screen and (max-width: 490px) {
  .wrapperBox {
    padding: 12px;
    background-color: var(--white);
    border-radius: 16px;
    gap: 12px;
    margin-top: 16px;
    font-size: 14px;
  }
  .onlyText {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}

.wrapperYesNo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .questionYesNo {
    font-weight: 700;
    line-height: 24px;
    color: var(--primary);
  }

  .wrapperButton {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .buttonYesNo {
      font-size: 16px;
      padding: 8px 16px;
      border-radius: 100px;
      font-weight: 700;
      text-align: center;
      border: 1px solid var(--primary);
      cursor: pointer;
    }

    .active {
      background-color: var(--primary);
      color: #fff;
    }

    .deActive {
      background-color: #fff;
      color: var(--primary);
    }
  }
}

@media only screen and (max-width: 490px) {
  .wrapperYesNo {
    .questionYesNo {
      line-height: 20px;
    }

    .wrapperButton {
      .buttonYesNo {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}
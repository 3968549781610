.RSPBprogressBar {
    width: calc(100% - 32px);
    height: 10px;
    margin-bottom: 30px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
}

.RSPBprogressBar .RSPBstep {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -15px;
    z-index: 0;
    width: max-content;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
    cursor: pointer;
}

.RSPBprogressBar .RSPBprogressBarText {
    color: white;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: rgba(0, 116, 217, 0.8);
    z-index: -1;
}

@media only screen and (max-width: 490px) {
    .RSPBprogressBar {
        width: calc(100% - 28px);
    }
    .RSPBprogressBar .RSPBstep {
        top: -11px;
    }
}
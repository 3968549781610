$PADDING: 16px;
$SIZE_BUTTON: 32px;
$DESCRIPTION: rgba(0, 0, 0, 0.6);

.item-event {
    background-color: var(--white);
    padding: $PADDING;
    display: flex;
    flex-direction: row;
    gap: 16px;
    border-radius: 24px;
    max-height: 200px;

    .event-image {
        .image-content {
            width: 60px;
            height: 60px;
            border-radius: 50%;

            .image {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 50%;
            }
        }
    }

    .event-information {
        .top-content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 4px;

            .eventName {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
                color: var(--text-color);
                text-align: left;
                flex: 2;
            }
            .eventName-theme {
                font-size: 20px;
                line-height: 24px;
            }
            .price-ticket {
                font-weight: 700;
                font-size: 22px;
                line-height: 24px;
                text-align: right;
                color: var(--text-primary);
                flex: 1;
                white-space: nowrap;
            }

            .ticket-sold-out {
                font-weight: 400;
                font-size: 22px;
                line-height: 24px;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.4);
                flex: 1;
                text-align: right;
            }
        }
        .bottom-content {
            display: flex;
            justify-content: unset;
            align-items: center;
            gap: unset;
            flex-direction: column;
        }
    }

    .description {
        font-weight: 400;
        font-size: 14px;
        color: $DESCRIPTION;
        font-family: var(--font-secondary);
        text-align: left;
        white-space: pre-line;
        width: 100%;
    }
    .description-light {
        font-size: 16px;
        line-height: 18px;
        color:rgba(0, 0, 0, 0.4);
    }
    
    .right {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        align-items: center;
        color: var(--black);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        flex: 1;

        .wrapper-btn {
            width: $SIZE_BUTTON;
            height: $SIZE_BUTTON;
            border-radius: 50%;
            background-color: var(--layout-btn);
            padding: 4px;
            user-select: none;
            
            .btn-chi {
                background-color: var(--primary);
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-content: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                color: var(--white);
                cursor: pointer;
                font-family: "Lato";

                &:hover {
                    background-color: var(--primary-darker);
                }
            }

            .btn-sold-out {
                pointer-events: none;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}

@media only screen and (max-width: 490px) {
    .item-event {
        padding: 12px;
        border-radius: 16px;
        .top-content {
            .eventName {
                font-size: 14px !important;
                line-height: 22px;
            }
            .eventName-theme {
                 font-size: 16px;
                 line-height: 22px;
             }
             .price-ticket {
                font-size: 16px;
                line-height: 22px;
            }

        }
        
        .description {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }
        .description-light {
            font-size: 14px;
            line-height: 16px;
        }
    }
}

$BACKGROUND:  rgba(17, 17, 17, 0.05);
$HEIGHT: 48px;
$HEIGHTMOBILE: 40px;
$INPUT_COLOR: rgba(0, 0, 0, 0.87);

.form-field {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    font-family: inherit;
    -webkit-appearance: none;
    color: $INPUT_COLOR;
    border: none;
    background: var(--white);
    padding-right:16px;
    font-family: var(--font-primary);
    transition: border .3s ease;
    padding-left: 1px;
    
    &::placeholder {
        color: var(--text-primary);
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        opacity: 0.6;
    }
    
    &:focus {
        outline: none;
    }
}

.form-group {
    background: var(--white);
    border-radius: 100px;
    border: 1px solid rgba(75, 85, 81, 0.16);
    position: relative;
    display: flex;
    height: $HEIGHT;
    width: 100%;

    &>label,
    .form-field {
        white-space: nowrap;
        display: block;
    }

    .form-field {
        position: relative;
        z-index: 1;
        flex: 1 1 auto;
        width: 1%;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 100px;
        padding-right: 20px;
        caret-color: $INPUT_COLOR;
    }
    .imageIcon {
        width: 24px;
        height: 24px;
    }

    &>label {
        text-align: center;
        padding: 8px 10px 8px 20px;
        font-size: 14px;
        line-height: 25px;
        color: var(--group-color);
        transition: background .3s ease, border .3s ease, color .3s ease;
        display: flex;
    }
}

@media only screen and (max-width: 490px) {
    .form-field {
        font-size: 14px;
        line-height: 20px;
        padding-right:12px;
        &::placeholder {
            font-size: 14px;
            line-height: 20px;
        }
    }
    
    .form-group {
        height: $HEIGHTMOBILE;
        .form-field {
            position: relative;
            z-index: 1;
            flex: 1 1 auto;
            width: 1%;
            margin-top: 0;
            margin-bottom: 0;
            border-radius: 100px;
            padding-right: 20px;
            caret-color: $INPUT_COLOR;
        }

        .imageIcon {
            width: 20px;
            height: 20px;
        }
    }
}

.wrapperCheckbox {
  font-weight: 700;
  color: var(--primary);

  .inputCheckbox {
    display: flex;
    justify-content: 'flex-start';

    .container {
      position: relative;
      cursor: pointer;
      user-select: none;
      margin-right: 35px;
    }

    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      width: 20px;
      height: 20px;
      background: #ddd;
      border-radius: 3px;
    }

    .container:hover .checkmark {
      background: #ccc;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .container .checkmark:after {
      top: 50%;
      left: 50%;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .container input:checked~.checkmark {
      background: #39719F;
    }

    .container input:checked~.checkmark:after {
      display: block;
    }

    .textCheckbox {
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: 490px) {
  .wrapperCheckbox {
    font-weight: 700;

    .inputCheckbox {
      .container {
        margin-right: 25px;
      }

      .checkmark {
        width: 16px;
        height: 16px;
      }

      .container .checkmark:after {
        width: 3px;
        height: 8px;
        border-width: 0 2px 2px 0;
      }

      .textCheckbox {
        line-height: 24px;
      }
    }
  }
}
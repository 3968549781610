$HEIGHT: 48px;
$HEIGHTMOBILE: 40px;

.wrapperInputText {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: var(--primary);

    .questionInputText {
        line-height: 24px;
    }

    .form-text-input {
        background: var(--white);
        border-radius: 100px;
        border: 1px solid rgba(75, 85, 81, 0.16);
        position: relative;
        display: flex;
        height: $HEIGHT;
        width: 100%;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .input-field {
            font-family: var(--font-primary);
            font-size: 18px;
            width: 100%;
            outline: none;
            border: none;
        }
    }
}

@media only screen and (max-width: 490px) {
    .wrapperInputText {
        .questionInputText {
            line-height: 20px;
        }

        .form-text-input {
            height: $HEIGHTMOBILE;
            padding: 0 12px;
            font-size: 14px;

            .input-field {
                font-size: 14px;
            }
        }
    }
}
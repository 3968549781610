$HEIGHT: 48px;

.wrapperAreaText {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--primary);

  .questionArea {
    line-height: 24px;
  }

  .form-text-area {
    background: var(--white);
    border-radius: 8px;
    border: 1px solid rgba(75, 85, 81, 0.16);
    position: relative;
    display: flex;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    min-width: 100%;
    outline: none;
    font-size: 18px;
    font-family: var(--font-primary);
  }
}

@media only screen and (max-width: 490px) {
  .wrapperAreaText {
    .questionArea {
      line-height: 20px;
    }

    .form-text-area {
      padding: 12px;
      font-size: 14px;
    }
  }
}
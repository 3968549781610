.title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: var(--text-title);
}
.title-light {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

@media only screen and (max-width: 490px) {
    .title {
        font-size: 16px;
        line-height: 22px;
    }
    .title-light {
        font-size: 16px;
        line-height: 22px;
    }
}
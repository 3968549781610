$LAYOUT_MAX_WIDTH: 480px;
$PADDING_PC: 24px;
$PADDING_MB: 16px;

.layout {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    
    .layout-container {
        width: $LAYOUT_MAX_WIDTH;
        padding: 40px $PADDING_PC $PADDING_PC;
        position: relative;
    }

    .layout-border {
        background: var(--background);
        border: 4px solid var(--border);
        box-shadow: 0px 40px 120px var(--shadow);
        border-radius: 48px;
        overflow: hidden;
    }
    
}

@media only screen and (max-width: 490px) {
    .layout {
        padding: 20px 16px;
        .layout-container {
            padding: 32px $PADDING_MB $PADDING_MB ;
        }
    }
}
.dropdown {
    z-index: 10000;
    position: relative;
}

.dropdown,
.dropdown * {
    z-index: 10;
}

.dropdown .dropdown-btn {
    cursor: pointer;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: var(--text-title);
    font-weight: 500;
    padding: 4px;
    border-radius: 100px;
    width: fit-content;
    display: flex;
    gap: 8px;
    justify-items: flex-start;
    transition: all 0.2 ease;
}

.dropdown-content {
    position: absolute;
    left: 0;
    width: fit-content;
    box-shadow: 0px 8px 32px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background-color: var(--white);
}

.dropdown-content .item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    border-radius: 16px;
    color: var(--text-title);
}

.dropdown-content .item:hover {
    background: #fcfcfc;
}

.circle-language {
    border: 1px solid #EDEEEE;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: all 0.2 ease;

    &__selected {
        border: 5px solid #EA5284;
        transition: all 0.2 ease;
    }
}
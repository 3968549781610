$HEIGHT: 48px;
$HEIGHTMOBILE: 40px;

.wrapperSelectButton {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    gap: 12px;
    display: '-webkit-box';
    color: var(--primary);

    .questionSelect {
        line-height: 24px;
    }
    .form-select-button {
        background: var(--white);
        border-radius: 100px;
        border: 1px solid rgba(75, 85, 81, 0.16);
        position: relative;
        display: flex;
        height: $HEIGHT;
        width: 100%;
        padding: 0 16px;
       display: flex;
       justify-content: space-between;
       align-items: center;
    }
    .placeHolder {
        opacity: 0.6;
    }

    .wrapperOptionSelect {
        border: 1px solid var(--primary);
        display: flex;
        flex-direction: column;
        // gap: 12px;
        display: '-webkit-box';
        padding: 12px 0px;
        border-radius: 16px;
        .optionSelect {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 24px;
            padding: 6px 16px;
        }
        .optionSelect:hover {
            background-color: #eee;
          }

    }
}

@media only screen and (max-width: 490px) {
    .wrapperSelectButton {
        gap: 10px;
        .questionSelect {
            line-height: 20px;
        }
        .form-select-button {
            height: $HEIGHTMOBILE;
            padding: 0 12px;
        }
        .wrapperOptionSelect {
            padding: 7px 0;
            .optionSelect {
                line-height: 18px;
                padding: 4px 12px;
            }
        }
    }
}

  
.button {
    font-family: var(--font-primary);
    background: var(--primary);
    border-radius: 100px;
    padding: 15px;
    color: var(--color_btn);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    border:1px solid var(--primary);
    // transition: all 0.2s;
    cursor: pointer;

    &:hover {
        background-color: var(--primary-darker);
    }

    &:disabled {
        background-color: var(--btn-disabled);
    }
}

.button-light {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}
.payment-service {
    background-color: var(--background);
    color: var(--text-title);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    padding-top: 24px;
}

.payment-service-light {
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
}

@media only screen and (max-width: 490px) {
    .payment-service {
        font-size: 18px;
        line-height: 22px;
        padding-top: 20px;
    }
    
    .payment-service-light {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }
}
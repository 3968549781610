.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .header-logo {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    .header-tagline {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
        text-align: center;
        text-transform: uppercase;
        color: var(--primaryTagline);
    }

    .header-title {
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--text-title);
        margin: 0;
        padding-top: 16px;
    }

    .header-title .header-title-event-name {
        font-size: 24px;
        line-height: 29px;
    }

    .header-title .header-title-event-time {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        opacity: 0.6;
        margin-top: 16px;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .header-title-light {
        font-size: 24px;
        line-height: 28px;
        font-weight: 400;
    }

    .header-logo-imageEvent {
        height: 100%;
        width:46%;
        margin: 16px 0 12px 0;
        border-radius: 16px;
    }
    .header-logo-default {
        height: 90px;
        width: 256.5px;
        margin: 16px 0 10px 0;
    }
}

@media only screen and (max-width: 490px) {
    .header {
        .header-tagline {
            font-size: 16px;
            line-height: 22px
        }
        .header-title .header-title-event-name{
            font-size: 16px;
            line-height: 22px;
        }
        .header-title .header-title-event-time{
            font-size: 16px;
            line-height: 22px;
        }
        .header-logo-imageEvent {
            margin: 12px 0 9px 0;
        }
        .header-logo-default {
            height: 64px;
            width: 180px;
            margin: 12px 0 6px 0;
        }
    }
}
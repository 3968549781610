.spinDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--secondary);
}

.spinStyled {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translateX(-50%, -50%);
  z-index: 9999;
  border: 10px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border-top-color: var(--primary);
  opacity: 1;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  width: 100px;
  height: 100px;
  transition: opacity 200ms;
  animation: RotateSpinner 0.8s linear;
  animation-iteration-count: infinite;
  transition-delay: 200ms;
}

@keyframes RotateSpinner {
  100% {
    transform: rotate(360deg);
  }
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 40px;

    .content-progress {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 18px 12px;
    }

    .content-title {
        padding: 28px 0;
        // text-align: center;
    }
}
.border-top {
    border-top: 1px solid var(--secondary);
}

@media only screen and (max-width: 490px) {
    .content {
        margin-top: 32px;
        .content-progress {
            padding: 0 12px 8px;
        }
        .content-title {
            padding: 14px 0;
        }
    }
}
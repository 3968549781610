.powered {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--text-title);
    cursor: pointer;
    
    span {
        color: var(--primary);
        font-weight: 700 ;
    }
    a {
        color: var(--primary);
        font-weight: 700 ;

    }
}

.powered-dark {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
}

.footer {
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .termsCondition {
        display: flex;
        gap: 12px;
    }
}

@media only screen and (max-width: 490px) {
    .powered {
        font-size: 12px;
        line-height: 14px;
    }
    .powered-dark {
        font-size: 12px;
        line-height: 16px;
    }
    .footer {
        padding-top: 18px;
        .termsCondition {
            display: flex;
            flex-direction: column;
            gap: 6px;
        }
    }
    
}